#stock-page {
    .main-container {
        margin: 100px;
        display: flex;
        width: 800px;
        height: 450px;
        box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.4);
    }

    .cards-menu {
        /* flex: 2; */
        // width: 300px;
        overflow-y: hidden;
        background: var(--background-gray);
    }

    .balance-sheet {
        /* flex: 4; */
        width: 500px;
        overflow-y: hidden;
        background: #fff;
    }

    .cards-menu:hover,
    .balance-sheet:hover {
        overflow-y: auto;
    }

    .cards-menu .header {
        width: 220px;
    }

    .header {
        margin: 40px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header h2 {
        font-weight: normal;
    }

    .card {
        background: #fff;
        color: var(--dark-gray);
        border: 1px solid var(--light-gray);
        border-radius: 5px;
        width: 220px;
        height: 115px;
        margin: 30px 10px;
        padding: 10px;
        box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
    }

    .sim-active {
        background-color: var(--primary);
        box-shadow: 0 0 0 3px var(--primary);
    }

    .selected,
    .card:hover {
        box-shadow: 0 0 0 3px var(--primary);
    }

    .card-disable {
        background: #e9ecef;
        // color: var(--dark-gray);
        // border: 1px solid var(--light-gray);
        // border-radius: 5px;
        // width: 220px;
        // height: 115px;
        // margin: 30px 10px;
        // padding: 10px;
        // box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
    }

    .card-disable:hover {
        box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
    }

    a {
        color: #000;
        text-decoration: none;
    }

    .card-logo {
        width: 50px;
        height: 50px;
    }

    .card p:last-child {
        font-size: 0.8rem;
        padding: 3px;
    }

    .balance-sheet .header {
        width: 420px;
    }

    .total-balance {
        font-size: 1.3rem;
        font-weight: bold;
    }

    .total-balance sup {
        font-size: 0.9rem;
    }

    .balance-movement {
        border-top: 1px solid var(--light-gray);
        width: 420px;
        margin: 0 40px;
    }

    .movement {
        display: flex;
        align-items: center;
        justify-items: left;
        justify-content: space-between;
        margin: 40px 0;
    }

    .movement img {
        width: 25px;
        border: 1px solid var(--light-gray);
        border-radius: 50%;
        padding: 5px;
        margin-right: 20px;
    }

    .movement-description h3 {
        font-weight: normal;
        font-size: 1.1rem;
    }

    .movement-description p {
        font-size: 0.8rem;
        color: var(--light-gray);
        margin-top: 3px;
    }

    .amount {
        font-weight: bold;
        margin-left: auto;
    }

    .negative {
        color: var(--blue);
    }

    .positive {
        color: var(--green);
    }

    .testimonial-group {
        overflow-x: auto;
        white-space: nowrap;
    }

    .inline-card {
        display: inline-block;
        float: none;
    }

    .paddles {
        float: right;
    }
    .paddle {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 3em;
    }
    .left-paddle {
        left: 0;
    }
    .right-paddle {
        right: 0;
    }
    .hidden {
        display: none;
    }

    .arrow-left {
        position: absolute;
        width: 2rem;
        height: auto;
        // top: 1.5rem;
        left: 0rem;
        z-index: 1;
        background-color: yellow;
        border: 1px solid black;
    }
    .arrow-right {
        position: absolute;
        width: 2rem;
        height: auto;
        // top: 1.5rem;
        right: 10rem;
        z-index: 1;
        background-color: yellow;
        border: 1px solid black;
    }
}