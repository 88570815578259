.digit-group {
    input {
        width: 50px !important;
        height: 50px;
        background-color: #fff;
        border: none;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
        // font-family: "Raleway", sans-serif;
        font-weight: 400;
        color: #3f4254;
        margin: 0 2px;
        border: 1px solid #e4e6ef;
        border-radius: 0.85rem;
    }
    input:focus {
        outline: none;
        border: 1px solid #f64e60;
    }
}
