#transactionpage {
    @media (min-width: 992px) {
        .header, .header-fixed .header {
            height: 80px;
            transition: all .3s ease;
        }

        .header {
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            background-color: #fff;
            position: relative;
            z-index: 2;
            border-bottom: 1px solid #ebedf3;
        }
    }

    .balance-info-height {
        height: 300px !important;
    }

    .responsive-calendar {
        /* by setting font-size, all the elements will correspond */
        font-size: 9px !important; /* default to 10px */
    }

    @media (max-width: 1500px) {
        .responsive-calendar {
            font-size: 8px !important;
        }
    }

    @media (max-width: 1200px) {
        .responsive-calendar {
            font-size: 7px !important;
        }
    }

    @media (max-width: 768px) {
        .responsive-calendar {
            font-size: 6px !important;
        }
    }

    /* Large screens */
    @media (min-width: 2500px) {
        .responsive-calendar {
            font-size: 12px !important;
        }
    }

    .balance-sheet .header {
        width: 420px;
    }

    .total-balance {
        font-size: 1.3rem;
        font-weight: bold;
    }

    .total-balance sup {
        font-size: 0.9rem;
    }

    .balance-movement {
        border-top: 1px solid var(--light-gray);
        width: 420px;
        margin: 0 40px;
    }

    .movement {
        display: flex;
        align-items: center;
        justify-items: left;
        justify-content: space-between;
        margin: 40px 0;
    }

    .movement img {
        width: 25px;
        border: 1px solid var(--light-gray);
        border-radius: 50%;
        padding: 5px;
        margin-right: 20px;
    }

    .movement-description h3 {
        font-weight: normal;
        font-size: 1.1rem;
    }

    .movement-description p {
        font-size: 0.8rem;
        color: var(--light-gray);
        margin-top: 3px;
    }

    .amount {
        font-weight: bold;
        margin-left: auto;
    }

    .negative {
        color: var(--danger);
    }

    .positive {
        color: var(--success);
    }
}
