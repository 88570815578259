.payment-icon input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.payment-img {
    border: 3px solid transparent;
    transition: transform 0.2s;
    filter: grayscale(100%);
}

.payment-img-active {
    border: 3px solid #26d07c;
}

.payment-img:active {
    opacity: 0.4;
    cursor: pointer;
}

.payment-img:hover {
    transform: scale(1.25);
}
