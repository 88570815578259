.aside {
    // background-color: #aeaeae !important;
    background-image: url(https://cdn.simk4.com/cooperative/aside-wallpaper.jpg) !important;
    background-size: cover !important;
}

.aside-menu {
    // background-color: #aeaeae !important;
    background-color: transparent !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #fff !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #fff !important;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:hover > .menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:hover > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #fff !important;
}

.aside-menu
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
.aside-menu
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    fill: #fff !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
    color: #fff !important;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
    color: #fff !important;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
    color: #fff !important;
}

.aside-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
.aside-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    fill: #fff !important;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link {
    background-color: #1b1464 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
    background-color: #1b1464 !important;
}

.aside-menu .menu-nav > .menu-item:hover > .menu-heading,
.aside-menu .menu-nav > .menu-item:hover > .menu-link {
    background-color: #1b1464 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
    background-color: #1b1464 !important;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:hover > .menu-link {
    background-color: #1b1464 !important;
}

// .aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon.svg-icon svg g [fill],
// .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon svg g [fill] {
//     fill: #647c90 !important;
// }

// .aside-menu
//     .menu-nav
//     > .menu-item
//     .menu-submenu
//     .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
//     > .menu-heading,
// .aside-menu
//     .menu-nav
//     > .menu-item
//     .menu-submenu
//     .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
//     > .menu-link {
//     background-color: #525252 !important;
// }
// .aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
// .aside-menu .menu-nav > .menu-item > .menu-link .menu-text :hover {
//     background-color: #525252 !important;
// }

@media (min-width: 992px) {
    .brand-dark .brand {
        background-color: #1b1464 !important;
        // background-color: #979797 !important;
        // background-color: transparent !important;
    }
}

.brand-dark .header-mobile {
    background-color: #1b1464 !important;
}

@media (max-width: 991.98px) {
    .brand-dark .header-mobile .burger-icon span {
        background-color: #fff !important;
    }

    .brand-dark .header-mobile .burger-icon span::before,
    .brand-dark .header-mobile .burger-icon span::after {
        background-color: #fff !important;
    }

    .brand-dark .header-mobile .burger-icon:hover span {
        background-color: #fff !important;
    }
}
